<template>
    <section class="page balance-page">
        <div class="top-header w-100">
            <div class="mb-title">
                <h1>{{ $t('assets.title') }}</h1>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="top-header-content d-flex align-items-center">
                            <div class="w-100">
                                <div class="assets">
                                    <div class="total-assets">
                                        <h1>{{ $t('assets.assets_property') }}</h1>
                                    </div>
                                    <div class="assets-left">
                                        <div class="row balance-actions">
                                            <div class="col balance-actions-box">
                                                <div>
                                                    <router-link to="/finance/orders" id="orders-btn">
                                                    {{ $t('balance.orders_title') }}
                                                    </router-link>
                                                </div>
                                                <div class="balance-actions-img" :class="{ 'active-class': isActive }">
                                                    <img src="@/assets/images/de-snut/deposit_withdraw.png" alt="">
                                                </div>
                                            </div>
                                            <div class="col balance-actions-box">
                                                <div>
                                                    <router-link to="/finance/history" id="orders-btnmb">
                                                        {{ $t('balance.history_title') }}
                                                    </router-link>
                                                </div>
                                                <div class="balance-actions-img2x">
                                                    <img src="@/assets/images/de-snut/assets_history.png" alt="">
                                                </div>
                                            </div>
                                            <div class="col balance-actions-box">
                                                <div>
                                                    <a href="javascript:;" @click="showExchangeModal" id="orders-btnmb">
                                                    {{ $t('assets.label_exchange') }}
                                                    </a>
                                                </div>
                                                <div class="balance-actions-img2x">
                                                    <img src="@/assets/images/de-snut/assets_exchange.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Do not display deposit/withdraw buttons when multi-coin supports are enabled. -->
                                <div v-if="bcConfig && !bcConfig.supportMultiCoinDeposits" class="mt-4">
                                    <a href="javascript:;" class="btn btn-primary ps-4 pe-4" @click="toggle_deposit()">
                                        {{ $t('general.deposit') }}
                                    </a>
                                    <router-link class="btn btn-secondary ps-4 pe-4" to="/finance/withdraw">{{
                                        $t('general.withdraw') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section v-if="bcConfig" class="w-100">
            <div class="container px-3 py-5">

                <!-- balance list here -->
                <balance-list-multi-coins v-if="bcConfig.supportMultiCoinDeposits === true" :balance_list="balance_list"
                    :futures_assets="futuresAssets" :bcConfig="bcConfig" />
                <balance-list v-else :balance_list="balance_list" :show_deposit_address="show_deposit_address"
                    :futures_assets="futuresAssets" :bcConfig="bcConfig" />
            </div>

            <futures-stats ref="futuresStats" @balance-updated="onFuturesStatsUpdated" />
            <asset-exchange-modal ref="exchangeModal" :balances="balance_list" :bonusToken="bonusToken"
                @balance-updated="onBalanceUpdated" />
        </section>
    </section>
</template>

<style scoped>
.page {
    background: var(--bg-primary);
}
</style>

<script>
import AssetExchangeModal from './Components/AssetExchangeModal.vue';
import FuturesStats from '../Components/FuturesOpenOrderStats.vue';
import BalanceList from './BalanceComponents/_BalanceList-4.1.vue';
import BalanceListMultiCoins from './BalanceComponents/_BalanceList-MultiCoins.vue';

import { setProfile } from 'utilities/helper';
let g_assetTypes = [];
let g_assetTypeMap = {};

export default {
    components: { AssetExchangeModal, FuturesStats, BalanceList, BalanceListMultiCoins },

    data() {
        return {
            balance_list: null,
            bonusToken: null,
            futuresAssets: '--',

            // Config settings for blockchain
            bcConfig: null,

            // Indicates whether to display deposit addresses or not.
            show_deposit_address: false,

            //top-header user information
            profile: null,
            //totalMoney
            totalMoney: 0,
            showTotalMoney: true,

            isActive:false
        };
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
        this.initAsync();

        if(this.$i18n.locale==='en'){
            this.isActive=true;
        }
    },
    watch: {
        '$i18n.locale': function (newVal, oldVal) {
            if(newVal==='en'){
                this.isActive=true;
            }
            
        }
    },
    computed: {
        userPhone() {
            return this.profile.phone
        },
        userUid() {
            return this.profile.referralCode
        },
    },
    methods: {
        initAsync: async function () {
            ///////////////////////////////////////////////////////////////////////////////////////
            // Get all asset types.
            const assetTypes = [];
            const assetTypeMap = {};

            // USDT and futures margin account will already be displayed at top
            assetTypes.push('USDT');
            assetTypes.push('FTUSDT');
            assetTypeMap['USDT'] = [];
            assetTypeMap['FTUSDT'] = [];

            // Then query blockchain config
            const resp = await $.callPostApi(this, '/api/v1/blockchain/config');
            if (!resp || !resp.data) {
                // exit for invalid config
                console.error('Invalid blockchain config.');
                return;
            }

            if (resp.data.supportedCoins) {
                $(resp.data.supportedCoins).each((index, item) => {
                    // Always use upper case
                    const upperCoin = item.currency.toUpperCase();

                    if (!assetTypeMap[upperCoin]) {
                        assetTypes.push(upperCoin);
                    }
                    assetTypeMap[upperCoin] = item;
                });
            }

            g_assetTypes = Object.freeze(assetTypes);
            g_assetTypeMap = Object.freeze(assetTypeMap);
            this.bonusToken = resp.data.bonusToken;
            this.bcConfig = Object.freeze(resp.data);

            ///////////////////////////////////////////////////////////////////////////////////////
            // Update balance list finally
            await this.syncBalancesAsync();
        },

        toggle_deposit: function () {
            this.show_deposit_address = !this.show_deposit_address;
        },

        syncBalancesAsync: async function () {
            const self = this;
            const balances = await $.callPostApi(this, '/api/v1/balance/list');

            const map = {};
            $(balances).each((index, raw_balance) => {
                map[raw_balance.currency] = new BalanceItem(raw_balance);
            });

            // Have configured tokens display at top
            const output = [];
            let ftItem = null;
            $(g_assetTypes).each((index, currency) => {
                let item = map[currency];
                if (!item) {
                    item = new BalanceItem({ currency: currency, balance: 0, frozen: 0 });
                } else {
                    delete map[currency];
                }
                if (currency === 'FTUSDT') {
                    ftItem = item;
                } else {
                    output.push(item);
                }
            });

            // Any remaining balance items?
            for (let currency in map) {
                output.push(map[currency]);
            }

            // Add futures currency at the last one
            if (ftItem) {
                output.push(ftItem);
            }

            self.balance_list = output;

            //get USDT totalMoney
            for (let balance in balances) {
                if (balances[balance].displayName == 'USDT') {
                    self.totalMoney = balances[balance].balance;
                }

            }
        },

        onBalanceUpdated: function () {
            const self = this;
            this.balance_list = null;

            setTimeout(() => {
                // Update balance, and resync open futures orders.
                self.syncBalancesAsync();
                self.$refs.futuresStats.reload();
            }, 500);
        },

        showExchangeModal: function () {
            this.$refs.exchangeModal.showModal();
        },

        /**
         * Update the available amount for the futures account.
         */
        onFuturesStatsUpdated: function () {
            const stats = this.$refs.futuresStats.getStats();
            this.futuresAssets = isNaN(stats.current_balance) ? '--' : stats.current_balance.toFixed(4);
        },
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                }

                self.profile = profile;

            }
        },
        //display xxx***xx str
        formatString(str) {
            // get str length
            const length = str.length;
            // Define the length of prefixes and suffixes to retain
            const prefixLength = 3;
            const suffixLength = 2;
            // Determine whether the string is long enough
            if (length <= prefixLength + suffixLength) {
                return str;
            }
            // Get prefix and suffix
            const prefix = str.slice(0, prefixLength);
            const suffix = str.slice(length - suffixLength);

            // Final result of splicing
            return `${prefix}***${suffix}`;
        },
    }
};

/**
 * BalanceItem
 */
function BalanceItem(balance) {
    // this.__raw_data = balance;

    // is the symbol associated to an exchange one?
    // const sym = _exchange_symbol_name_map[balance.currency];
    // const precision = sym ? sym.volumePrecision : 2;

    // hard-coded to use 4 precision
    const precision = 4;

    this.available = (balance.balance + balance.frozen).formatBalance(precision);
    this.balance = balance.balance.formatBalance(precision);
    this.frozen = (-balance.frozen).formatBalance(precision);
    // this.incentive = balance.incentive.floor(precision) * 1;

    this.display_name = balance.displayName || balance.currency;
    this.currency = balance.currency;
}
</script>
<style scoped>
.mt-4 a,
.balance-actions a {
    background: #fff;
    font-family: PingFangSC, PingFang SC;
    color: #132EAF;
    border-radius: 37px;
    border: 1px solid #fff;
    min-width: 6rem;
}
#orders-btn{
    background: #fff;
    color: #132EAF;
    max-width: 200px;
}
.mt-4 a:hover,
.balance-actions a:hover {
    background: #0C2780;
    color: #fff;
}

.balance-actions .col {
    flex: 0 0 auto;
    width: auto;
}

.balance-actions .a:hover {
    background: #0C2780;
}

.transfer a {
    color: #000;
}

.top-header {
    background: #fff;
    color: #000;
    height: 300px;
    margin-top: 50px;
}

.top-header .container {
    margin-top: 10px;
}

.user-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.avatar {
    width: 66px;
}

.total-assets {
    display: flex;
    align-items: center;
    padding: 2rem 0;
    align-self: flex-start;
}

.eyes {
    display: none;
    width: 14px;
    margin-left: 10px;
}

.mb-title {
    display: none;
}

.username {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #111111;
}

.user-box-right {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.vip-box {
    display: flex;
    position: relative;
}

.vip-img {
    width: 60px;
    margin-right: 5px;
}

.vip-img-sm {
    width: 50px;
    margin-right: 5px;
}

.vip-label {
    justify-content: center;
    position: absolute;
    right: -70px;
}

.user-login-info {
    display: flex;
    align-items: center;
    min-width: 150px;
}

.vip-label-sm {
    justify-content: center;
    margin-left: 5px;
}

.user-info {
    display: flex;
    justify-content: flex-start;
}

.user-info-item {
    display: flex;
    margin-left: 20px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #E1E2E6;
    padding: 5px 8px 5px 8px;
    margin-top: 5px;
}

.user-info-item:first-child {
    margin-left: 0;
}

.info-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #7F8490;
    line-height: 22px;
    margin-right: 8px;
}

.info-value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #18191C;
    line-height: 22px;
}

.user-top-header-md {
    padding: 30px 16px 0;
    background-color: #fff;
}

.user-box-md {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
}

.user-box-left {
    display: flex;
    align-items: center;
}

.avatar-md {
    width: 30px;
    margin-right: 5px;
}

.user-name {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 21px;
    color: #111111;
    line-height: 17px;
    margin-bottom: 5px;
}

.login-time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 18px;
}

.user-box-btn {
    border-radius: 20px;
    border: 1px solid #18191C;
    padding: 8px 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #000922;
    line-height: 10px;
    cursor: pointer;
}

.user-info-box-md {
    display: flex;
    flex-wrap: wrap;
}

.user-info-box-md .user-info-item {
    padding: 0 20px 0 0;
    display: block;
    margin: 0 0 20px;
    flex: 1 0 100px;
    min-width: 100px;
    max-width: 100%;
}

.item-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #7F8490;
    line-height: 22px;
}

.item-value {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #18191C;
    line-height: 22px;
}

.totalMoneyStyle {
    font-size: 44px;
    font-weight: bold;
}

.assets {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    color: #000;
    border-radius: 20px 20px 20px 20px;
}

.balance-actions {
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
}
.assets-img img {
  max-width: 100%; 
  height: auto; 
}

.balance-actions-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #DEE8FF;
    border-radius: 4px 4px 4px 4px;
    height: 130px;
}
.balance-actions-img{
    position: relative;
    top: -13px; 
    overflow: visible; 
}
.balance-actions-img2x{
    position: relative;
    top: -20px; 
    right: -20px;
    overflow: visible; 
}
@media screen and ( min-width :1024px) and ( max-width :1400px){
    .balance-actions-box{
        max-width: 300px;
    }
    #orders-btn{
        position: relative;
        z-index: 1;
    }
    .active-class{
        position: relative;
        right: 24px;
        z-index: 0;
    }
    .balance-actions-img2x{
        width: 166px;
        height: 131px;
        top: -58px;
        right: 17px;
    }
}
@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }

    .d-block {
        display: block !important;
    }

    .top-header {
        background: #fff;
        color: #000;
        height: 580px;
        margin-top: 0;
    }

    .assets {
        background-repeat: no-repeat;
        min-height: 100px;
        background-size: 100% 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
    }

    .assets-img {
        display: none;
    }

    .eyes,
    .mb-title {
        display: flex;
    }

    .mb-title {
        padding-left: 10px;
        align-items: center;
        width: 100%;
        height: 44px;
        background: #FFFFFF;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
        border-radius: 0px 0px 0px 0px;
    }

    .mb-title h1,
    .assets-left h1 {
        font-family: PingFang SC, PingFang SC;
        font-size: 20px;
    }
    .balance-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        flex-wrap: nowrap;
    }

    .balance-actions-box{
        margin-bottom: 30px;
    }
    .totalMoneyStyle {
        font-size: 22px;
    }

    .row .balance-actions {
        flex-wrap: wrap;
        margin: 0;
    }

    .total-assets {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        padding-left: calc(var(--bs-gutter-x)* 0.5);
    }

    #orders-btnmb{
    background: #fff;
    color: #132EAF;
}
}
</style>