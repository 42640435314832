<template>
  <section class="page home-page">
    <section class="pro_layout">
      <loading-indicator v-if="!symbols" />
      <div v-else>
        <div class="home-top">
          <top-banners
            v-if="context.homeConfig"
            :home_config="context.homeConfig"
          />
        </div>

        <!-- top notice -->
        <div
          class="page-part"
          v-if="
            context.homeConfig &&
            context.homeConfig.promptedNotices &&
            context.homeConfig.promptedNotices.length
          "
        >
          <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
        </div>

        <!-- major nav icons for sm screens -->
        <div class="d-block d-md-none sm-navs">
          <div class="container">
            <div class="row">
              <div class="col">
                <router-link to="/user/login">
                  <svg class="svg-icon" viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-login" />
                  </svg>
                  {{ $t("general.login") }}/{{ $t("general.register") }}
                </router-link>
              </div>
              <div class="col">
                <!-- Display a tab for the savings feature when it was enabled. -->
                <router-link v-if="sysconfig.savingsEnabled" to="/saving">
                  <svg class="svg-icon" viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                  </svg>
                  {{ $t("home.header_saving", { platform: sysconfig.name }) }}
                </router-link>
                <router-link v-else to="/user/balance">
                  <svg class="svg-icon" viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                  </svg>
                  {{
                    $t(
                      sysconfig.is_hkmy
                        ? "home.header_buy_crypto_my"
                        : "home.header_buy_crypto"
                    )
                  }}
                </router-link>
              </div>
              <div class="col">
                <router-link to="/startup">
                  <svg class="svg-icon" viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-assets" />
                  </svg>
                  {{
                    $t(
                      sysconfig.is_hk ? "general.startup_hk" : "general.startup"
                    )
                  }}
                </router-link>
              </div>
              <div class="col">
                <router-link to="/notices">
                  <div class="svg-box">
                    <svg class="svg-icon notice-svg" viewBox="0 0 256 256">
                      <use
                        class="use-svg"
                        xlink:href="/dist/svg/icons.svg#v4.2-notice"
                      />
                    </svg>
                  </div>
                  {{ $t("notices.notice_title") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <inline-svg-icons :symbols="symbols" />
        <!-- <home-quotes ref="quotes" :symbols="symbols" /> -->
        <section class="home-section">
          <div class="container">
            <div class="title1">{{ $t("home.home_technology") }}</div>
            <div class="title2">{{ $t("home.h1") }}</div>
            <div class="row">
              <div class="col">
                <div class="main-block-wrapper">
                  <home-quotes ref="quotes" :symbols="symbols" />
                </div>
                <div class="more">
                  <router-link to="/quotes" class="d-flex align-items-center">
                    {{ $t("home.label_more") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <introduction />
        <partner-links
          v-if="
            context && context.homeConfig && context.homeConfig.spl === true
          "
        />
      </div>
    </section>
  </section>
</template>

<style scoped lang="less">
.pro_layout{
  background:#F9F9F9;
}
.home-section {
    // margin-top: 30px;
    background:#F9F9F9;
    .container.more {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main-block-wrapper {
       margin-top: 30px;
    }
    .container .more a {
        padding: 5px 10px;
        border-radius: 20px;
        border: 1px solid #000;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .container .more {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .title1{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 10px;
        padding-left: 10px;
        color: #000;
    }
    .title2{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 22px;
        color: #000;
        padding-left: 10px;
    }
}

/* ** */
.sm-navs {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}
.sm-navs a {
    color: #000;
    font-size: 12px;
}

.sm-navs svg.svg-icon {
    display: block;
    height: 1.5rem;
    width: auto;
    margin: auto;
    margin-bottom: 0.5rem;
    fill: var(--primary-base);
}

.svg-box {
    height: 1.5rem;
    position: relative;
    margin-bottom: 0.5rem;
}

.sm-navs svg.svg-icon.notice-svg {
    height: 2.8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .d-md-none {
    display: block !important;
  }
}
@media (max-width: 1024px) {

}
</style>

<script type="text/javascript">
// import PostsComponent from './Components/Posts.vue';
import SymbolList from "./Components/SymbolList.vue";
import TopBanners from "./Components/TopBanners-4.2.vue";
import HomeNotices from "./Components/HomeNotices.vue";
import HomePosts from "./Components/HomePosts.vue";
// import Features from './Components/Features.vue';
import PromptedSymbols from "./Components/PromptedSymbols.vue";
import Introduction from "./Components/Introduction-4.2.vue";
import HomeQuotes from "./Components/HomeQuotes.vue";
import HomeCarousel from "./Components/HomeCarousel.vue";
import InlineSvgIcons from "../Components/_InlineSvgIcons.vue";
import PartnerLinks from "./Components/_PartnerLinks-4.2.vue";

// import '@/assets/svg/icon-rocket.svg';
// import '@/assets/svg/icon-team.svg';
import "@/assets/svg/icon-login.svg";
import "@/assets/svg/icon-wallet.svg";
import "@/assets/svg/icon-assets.svg";
// import '@/assets/svg/icon-trust.svg';
// import '@/assets/svg/icon-dollar-coin.svg';
import "@/assets/svg/icon-invite.svg";
import "@/assets/svg/icon-notice.svg";

import "@/assets/svg/v4.2/v4.2-login.svg";
import "@/assets/svg/v4.2/v4.2-wallet.svg";
import "@/assets/svg/v4.2/v4.2-assets.svg";
import "@/assets/svg/v4.2/v4.2-notice.svg";

import { SymbolInfo } from "utilities/SymbolInfo.js";
import { getSymbolManagerAsync, getAppContext } from "utilities/helper";

let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
  components: {
    TopBanners,
    SymbolList,
    HomeNotices,
    HomePosts,
    Introduction,
    PromptedSymbols,
    HomeQuotes,
    HomeCarousel,
    InlineSvgIcons,
    PartnerLinks
  },

  data() {
    return {
      // All symbols.
      symbols: null,
      // App context
      context: null
    };
  },

  computed: {
    // return a list of prompted symbols
    promptedSymbols: function () {
      let arr = this.symbols.filter(function (s) {
        return s.metadata.prompted && !s.metadata.testOnly;
      });
      return arr;
    }
  },

  created() {
    g_destoryed = false;
    this.context = getAppContext();
    // clear all existing symbols
    g_symbolMap = {};
    this.initPageAsync();
  },

  beforeDestroy() {
    g_destoryed = true;

    // clear all timers
    clearTimeout(g_quoteTimerId);
  },

  methods: {
    initPageAsync: async function () {
      // Read symbols.
      const mgr = await getSymbolManagerAsync();
      const sidMap = {};
      if (mgr) {
        const self = this;

        let arr = [];
        $(mgr.getAllSymbols()).each((index, item) => {
          let sym = new SymbolInfo(item);
          arr.push(sym);

          sidMap[sym.metadata.id] = sym;
        });

        g_symbolMap = Object.freeze(sidMap);
        self.symbols = arr;

        Vue.nextTick(() => {
          // start to subscribe realtime quote data
          self.syncQuotes();
        });
      }
    },

    gotoTradePage: function (sym) {
      let route = sym.getRoutePath();
      this.$router.push({ path: route });
    },

    syncQuotes: function () {
      const self = this;
      // clear existing timer if any
      clearTimeout(g_quoteTimerId);

      const sids = [];
      $(this.promptedSymbols).each((index, sym) => {
        sids.push(sym.metadata.id);
      });

      try {
        const temp = this.$refs.quotes.getVisibleSids();
        if (Array.isArray(temp)) {
          for (let i = 0; i < temp.length; i++) {
            sids.push(temp[i]);
          }
        }
      } catch (err) {
        console.error(err);
      }

      if (sids.length > 0) {
        // Read quotes from server
        self.$http
          .get("/api/v1/quotation/latest?symbols=" + sids.join(","))
          .then((json) => {
            const quotes = json.data;
            if (quotes.length > 0) {
              for (let i = 0; i < quotes.length; i++) {
                const quote = quotes[i];
                const sym = g_symbolMap[quote.id];
                if (sym) {
                  sym.update(quote);
                }
              }
            }
          })
          .then(() => {
            if (g_destoryed === false) {
              // always restart timer
              let delay = 4000 + Math.round(Math.random() * 3000);
              g_quoteTimerId = setTimeout(() => {
                self.syncQuotes();
              }, delay);
            }
          });
      }
    }
  }
};
</script>
