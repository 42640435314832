<template>
   <section>
     <section class="section1">
        <div class="buy-coins container">
            <div class="coins-img">
                <img  src="@/assets\images\de-snut\block.png" alt="" />
            </div>
            <div class="coins-left">
                <div class="title"><span class="logo">{{sysconfig.name}}</span></div>
                <div class="title"><span class="descript">{{$t('home.home_fluidity')}}</span></div>
                <ul>
                    <li>{{$t('home.home_profit')}}</li>
                    <li>{{$t('home.home_profit')}}</li>
                    <li>{{$t('home.home_profit')}}</li>
                    <li>{{$t('home.home_profit')}}</li>
                </ul>
                <div class="more">
                  <router-link to="/quotes" class="d-flex align-items-center">
                    {{ $t("home.label_more") }}
                  </router-link>
                </div>
            </div>
        </div>         
    </section>
    <section class="section2"> 
        <div class="content container">
           <div class="title"><span class="logo">{{sysconfig.name}}</span></div>  
           <div class="text">
                <div class="text1"><span class="logo">{{$t('home.home_invest')}}</span></div>
                <div class="text2"><span class="descript">{{$t('home.home_fluidity')}}</span></div>
                <div class="more">
                   <router-link to="/user/create" class="d-flex align-items-center">
                        {{ $t("home.guide_register") }}
                   </router-link>
                </div>
           </div>
        </div>
    </section>
    <div class="help container">
        <div onclick="toggle_udesk()"> {{ $t("intro_43.needs_help") }}</div>
    </div>
   </section>
</template>
<script>
export default {
    data() {
        return {
           
        }
    },
    created() { },
    methods: {

    }
}
</script>
<style lang="less" scoped>
ul,li{
    margin: 0;
    padding: 0;
}
.section1{
    background: #F9F9F9;
    .buy-coins{
        border-radius: 0px 0px 0px 0px;
        width: 100%;
        min-height: 450px;
        display: flex;
        padding: 0 10px;
        .coins-img{
            width: 40%;
        }
        .coins-left{
            width: 60%;
        }
        .coins-left{
            padding-top: 0;
            padding-left: 40px;
            padding-right: 20px;
            padding-bottom: 20px;
            color: #000;
            .title{
                font-weight: 500;
                font-size: 22px;
                .logo{
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 600;
                    color: #B3B3B3;
                }
                .descript{
                   font-weight: 600;
                   color: #000; 
                }
                .img{
                    width: 100px;
                    height: 50px;
                }
            }
            ul{
                margin-left: 15px;
                font-size: 14px;
                li{
                    margin: 20px 0;
                }
            }
            .more {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 30px;
                .align-items-center{
                    width: 150px;
                    height: 35px;
                    background: linear-gradient( 90deg, #000000 0%, #132EAF 100%);
                    border-radius: 203px 203px 203px 203px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .coins-img{
            text-align: left;
            padding: 10px;
            img{
                width: 390px;
                height: 400px;
            }
        }
    }
}
.section2{
    padding: 20px;
    margin-top: 30px;
   .content{
      background: url('@/assets/images/de-snut/board@2x.png') no-repeat;
      background-size: 100% 100%;
      height: 800px;
      position: relative;
      .title{
        height: 38px;
        width: 160px;
        font-size: 22px;
        background: linear-gradient( 90deg, rgba(216,216,216,0) 0%, rgba(49,49,49,0.3101) 22%, rgba(0,0,0,0.4) 100%);
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .text{
        position: absolute;
        left: 0;
        bottom: 0;
        height: 50%;
        width: 100%;
        background: linear-gradient( 90deg, #fff 4%, rgba(216,216,216,0) 100%);
        padding: 8% 20px 20px 30px;
        .text1{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 22px;
            color: #242428;
        }
        .text2{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #7F8490;
            margin-top: 20px;
        }
        .more {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 30px;
                .align-items-center{
                    width: 110px;
                    height: 35px;
                    background: #000;
                    border-radius: 203px 203px 203px 203px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
        }
      }
   } 
} 
.help{
    >div{
        margin: 20px 10px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #132EAF;
        cursor: pointer;
    }  
}


@media (max-width: 992px) {
    .section2{
        .content{
           min-height: 500px;
           max-height: 700px;
        } 
    }
}
@media (max-width: 768px) {
    .section2{
        .content{
            .text{
                padding: 10px;
            }
        } 
    }
}
@media (max-width: 445px) {
    .section2{
        .content{
            min-height: 400px;
            max-height: 500px;
        } 
    }
}
@media (max-width: 1024px)
{   
    .section1{
        .buy-coins{
            min-height: 350px;
            display: flex;
            flex-direction: column;
            .coins-img{
                width: 100%;
                img{
                    width: 100%;
                }
            }
            .coins-left{
                width: 100%;
                margin-top: -120px;
                padding-left: 20px;
            }
        }
    }

}

</style>
